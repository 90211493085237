import React, { FC } from "react";
import { imageSizes } from "~/foundation/Theme";
import { Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { ImageField, LinkField, RichTextField, sc, TextField, useSitecoreContext } from "~/foundation/Jss";
import { ExtendedBox } from "../ExtendedBox";
import { useParallax } from "react-scroll-parallax";
import { getLinkVariants, sizes as LinkSizes } from "~/foundation/Theme/components/Link";
import LinkIconWrapper from "../Link/LinkIconWrapper";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";
import { Icon } from "../Icon";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { useTranslation } from "~/foundation/Dictionary";

type SplitTeaserBaseProps = {
	fields?: {
		teaserSecondaryLink1?: LinkField;
		teaserSecondaryLink2?: LinkField;
		teaserTrumpet?: TextField;
		teaserDescription?: RichTextField;
		teaserHeadline?: TextField;
		teaserImage?: ImageField;
		teaserLink?: LinkField;
	},
	dataSource?: string,
	imageLast: boolean,
	hasAudioFile?: boolean;
	editable?: boolean
}

export const SplitTeaserBase: FC<SplitTeaserBaseProps> = ({ fields, dataSource, imageLast, editable = true }) => {
	const { sitecoreContext } = useSitecoreContext();
	const showImage = fields?.teaserImage?.value?.src || sitecoreContext.pageEditing;
	let parallax = {
		ref: React.createRef<HTMLDivElement>()
	};

	if (showImage && !sitecoreContext.pageEditing) {
		parallax = useParallax<HTMLDivElement>({
			easing: [.35,1,.45,1],
			translateY: [0, -15],
			rootMargin: { top: -200, right: 0, bottom: 0, left: 0 }
		});
	}

	return (
		<Flex
			alignItems="stretch"
			flexWrap="wrap"
			flexDirection="row"
			borderRadius="lg"
			borderBottomRightRadius="0"
			transform="translateZ(0)" //to fix safari border-radius
			overflow="hidden">
			<Box
				flexBasis={["100%", null, "50%"]}
				w={["100%", null, "50%"]}
				order={imageLast ? 1 : 0}
				flexShrink={["1", null, "0"]}
				alignSelf="stretch"
				minH={["0", null, "37rem"]}
				overflow="hidden"
				sx={{
					'.LazyLoad': {
						height: "100%",
					} }}>
				{(fields?.teaserImage?.value || sitecoreContext.pageEditing && editable) &&
					<Box ref={parallax.ref} height={sitecoreContext.pageEditing ? "100%" : "120% !important"}>
						<sc.AdvanceImage
							field={fields?.teaserImage}
							itemId={dataSource}
							fieldNames={["teaser image"]}
							editable={editable}
							sizes="(min-width: 1760px) 752px, (min-width: 1440px) 592px, (min-width: 768px) 482px, 100vw"
							srcSet={[imageSizes.teaser.square.sm, imageSizes.teaser.square.md, imageSizes.teaser.square.lg]}
							sx={{
								objectPosition: "center center",
								objectFit: ["cover", null, "cover"],
								minWidth: "100%",
								minHeight: "100%"
							}}
						/>
					</Box>
				}
			</Box>
			<ExtendedBox
				display="flex"
				variant="themed"
				flex={["0 0 100%", null, "0 0 50%"]}
				w={["100%", null, "50%"]}
				py={["12", null, "16"]}
				ps={["6", null, "16", null, imageLast ? "6.25rem" : "6.875rem"]}
				pe={["6", null, "16", null, imageLast ? "6.875rem" : "6.25rem"]}>
				<Box alignSelf="center">
					{(fields?.teaserTrumpet?.value || sitecoreContext.pageEditing && editable) &&
						<ChakraText variant="overline">
							<sc.Text field={fields?.teaserTrumpet} editable={editable}/>
						</ChakraText>
					}
					{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing && editable) &&
						<Heading as="h3" size="xs" mt="4">
							<sc.Text field={fields?.teaserHeadline} editable={editable}/>
						</Heading>
					}
					{(fields?.teaserDescription?.value || sitecoreContext.pageEditing && editable) &&
						<ChakraText
							as="span"
							size="body"
							mt="4"
							display="block"
							sx={{
								'a': {
									textDecoration: "underline"
								}
							}}>
							<sc.RichText field={fields?.teaserDescription} editable={editable}/>
						</ChakraText>
					}
					{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing && editable) &&
						<ExtendedBox mt="8" variant="themed-link">
							<sc.Link field={fields?.teaserLink} variant="primary" size="md" editable={editable} />
						</ExtendedBox>
					}
					{(fields?.teaserSecondaryLink1?.value.href || sitecoreContext.pageEditing && editable) &&
						<ExtendedBox mt="6" variant="themed-link">
							<sc.Link field={fields?.teaserSecondaryLink1} variant="secondary" size="md" editable={editable} />
						</ExtendedBox>
					}
					{(fields?.teaserSecondaryLink2?.value.href || sitecoreContext.pageEditing && editable) &&
						<ExtendedBox mt="4" variant="themed-link">
							<sc.Link field={fields?.teaserSecondaryLink2} variant="secondary" size="md" editable={editable} />
						</ExtendedBox>
					}
				</Box>
			</ExtendedBox>
		</Flex>
	);
};

export const SplitTeaserBaseFullLink: FC<SplitTeaserBaseProps> = ({ fields, dataSource, imageLast, hasAudioFile, editable = true }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [t] = useTranslation<GlobalDictionary>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const showImage = fields?.teaserImage?.value?.src || sitecoreContext.pageEditing;
	const linkVariants = getLinkVariants(isRtl);

	if (!fields?.teaserLink?.value.href){
		return <></>
	}

	let parallax = {
		ref: React.createRef<HTMLDivElement>()
	};

	if (showImage && !sitecoreContext.pageEditing) {
		parallax = useParallax<HTMLDivElement>({
			easing: [.35,1,.45,1],
			translateY: [0, -15],
			rootMargin: { top: -200, right: 0, bottom: 0, left: 0 }
		});
	}

	return (
		<ChakraRouterLink
			variant="unstyled"
			display="block"
			w="100%"
			h="100%"
			to={fields.teaserLink.value.href!}
		>
			<Flex
				alignItems="stretch"
				flexWrap="wrap"
				flexDirection="row"
				borderRadius="lg"
				borderBottomRightRadius="0"
				transform="translateZ(0)" //to fix safari border-radius
				overflow="hidden">
				<Box
					flexBasis={["100%", null, "50%"]}
					w={["100%", null, "50%"]}
					order={imageLast ? 1 : 0}
					flexShrink={["1", null, "0"]}
					alignSelf="stretch"
					minH={["0", null, "37rem"]}
					overflow="hidden"
					sx={{
						'.LazyLoad': {
							height: "100%",
						} }}>
					{(fields?.teaserImage?.value || sitecoreContext.pageEditing && editable) &&
						<Box ref={parallax.ref} height={sitecoreContext.pageEditing ? "100%" : "120% !important"}>
							<sc.AdvanceImage
								field={fields.teaserImage}
								itemId={dataSource}
								fieldNames={["teaser image"]}
								editable={editable}
								sizes="(min-width: 1760px) 752px, (min-width: 1440px) 592px, (min-width: 768px) 482px, 100vw"
								srcSet={[imageSizes.teaser.square.sm, imageSizes.teaser.square.md, imageSizes.teaser.square.lg]}
								sx={{
									objectPosition: "center center",
									objectFit: ["cover", null, "cover"],
									minWidth: "100%",
									minHeight: "100%"
								}}
							/>
						</Box>
					}
				</Box>
				<ExtendedBox
					display="flex"
					variant="themed"
					flex={["0 0 100%", null, "0 0 50%"]}
					w={["100%", null, "50%"]}
					py={["12", null, "16"]}
					ps={["6", null, "16", null, imageLast ? "6.25rem" : "6.875rem"]}
					pe={["6", null, "16", null, imageLast ? "6.875rem" : "6.25rem"]}>
					<Box alignSelf="center">
						{(fields?.teaserTrumpet?.value || sitecoreContext.pageEditing && editable) &&
							<ChakraText variant="overline">
								<sc.Text field={fields?.teaserTrumpet} editable={editable}/>
							</ChakraText>
						}
						{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing && editable) &&
							<Heading as="h2" size="xs" mt="4">
								<sc.Text field={fields?.teaserHeadline} editable={editable}/>
							</Heading>
						}
						{(fields?.teaserDescription?.value || sitecoreContext.pageEditing && editable) &&
							<ChakraText
								as="span"
								size="body"
								mt="4"
								display="block"
								sx={{
									'a': {
										textDecoration: "underline"
									}
								}}>
								<sc.RichText field={fields?.teaserDescription} editable={editable}/>
							</ChakraText>
						}
						{hasAudioFile && (
							<Flex gap="2" alignItems="center" mt="4">
								<Icon variant="Headphones" w="1.25rem" h="1.25rem" mt="-1" />
								<ChakraText variant="footerBody">
									{t("general.labels.audio")}
								</ChakraText>
							</Flex>
						)}
						<ExtendedBox
							fontSize="xs"
							lineHeight="1.125rem"
							letterSpacing="wider"
							mt="8"
							sx={linkVariants.primary}
							variant="themed-link">
							{fields?.teaserLink?.value.text &&
								<Box as="span">{fields.teaserLink.value.text}</Box>
							}
							<Box className="icon-container" sx={LinkSizes.md[".icon-container"]} ms="4">
								<LinkIconWrapper linkValue={fields?.teaserLink.value} w="icons.md" rtl={isRtl}/>
							</Box>
						</ExtendedBox>
					</Box>
				</ExtendedBox>
			</Flex>
		</ChakraRouterLink>
	);
};