import React, { FC } from "react";
import { SplitTeaserProps } from "..//generated-types";
import { themable } from "~/foundation/Theme";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { orientation } from "../base";
import { Ribbon } from "~/foundation/Components/Ribbon";
import * as variantThemes from "../SplitTeaser/variants";
import { SplitTeaserBase } from "~/foundation/Components/SplitTeaserBase";
import { GenericHeader } from "~/foundation/Components/GenericHeader";

const SplitTeaser: FC<SplitTeaserProps> = ({ fields, rendering, params }) => {
	const imageLast: boolean = params.orientation === orientation.TextImage;

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>			
			<ContentWrapper py={["0", null]}>
				<SplitTeaserBase fields={fields} dataSource={rendering.dataSource} imageLast={imageLast} />
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable({ variantThemes })(SplitTeaser);