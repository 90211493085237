import { ComponentStyleConfig } from "@chakra-ui/react";
import { ExtendTheme } from "~/foundation/Theme";

export default <Partial<ExtendTheme>>{
	name: "Default",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.darkGreen",
					color: "primary.white",
					linkColor: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const white = <Partial<ExtendTheme>>{
	name: "White",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.white",
					color: "primary.text",
				},
				"themed-link": {
					color: "primary.aramcoLinkBlue",
				},
			}
		}
	}
};

export const brightGrey = <Partial<ExtendTheme>>{
	name: "Bright Grey",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.brightGrey",
					color: "primary.text",
				},
				"themed-link": {
					color: "primary.text",
				},
			}
		}
	}
};

export const coldGreen = <Partial<ExtendTheme>>{
	name: "Cold Green",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "themeBackgroundColors.coldGreen",
					color: "primary.text",
				},
				"themed-link": {
					color: "primary.text",
				},
			}
		}
	}
};

export const aramcoGrey = <Partial<ExtendTheme>>{
	name: "Aramco Grey",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.aramcoGrey",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const darkGrey = <Partial<ExtendTheme>>{
	name: "Dark Grey",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.darkGrey",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const darkGreyGradient = <Partial<ExtendTheme>>{
	name: "Dark Grey Gradient",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "themeBackgroundColors.darkGreyGradient",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const aramcoBlue = <Partial<ExtendTheme>>{
	name: "Aramco Blue",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.aramcoBlue",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const darkBlue = <Partial<ExtendTheme>>{
	name: "Dark Blue",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.darkBlue",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};

export const britishRacingGreen = <Partial<ExtendTheme>>{
	name: "British Racing Green",
	components: {
		ExtendedBox: <ComponentStyleConfig>{
			variants: {
				"themed": {
					bg: "primary.britishRacingGreen",
					color: "primary.white",
				},
				"themed-link": {
					color: "primary.white",
				},
			}
		}
	}
};